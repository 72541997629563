
import settings from '@/plugins/settings';
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ICustomerQuestionnaire } from '../types';

@Component
export default class BannerQuest extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;

  questShow = false

  get bannerStyle() {
    let style = ''
    style += 'background: ' + settings.company.companyUISettings.banners.quest.background + '; '
    return style
  }

  get quest(): ICustomerQuestionnaire | null {
    return wallet.questionnaire
  }

  refreshSettings() {
    this.$router.go(0)
  }

  action() {
    this.questShow = true;
  }

  async later() {
    session.setQuestAsk(false)
  }
}

