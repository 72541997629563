
import $ph from '@/plugins/phoenix'
import settings from '@/plugins/settings';
import { session, wallet } from '@/plugins/store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CURRENCY_DEFAULT, OPER_DEFAULT } from '../store';
import { CurrencyType, IAccount, ICurrency, IDashboardItem, IOperation, OperTypeModes } from '../types';

@Component
export default class DashboardItem extends Vue {
  @Prop() readonly item: IDashboardItem | undefined;
  @Prop(Number) readonly index: number | undefined;
  @Prop(Boolean) readonly follow: boolean | undefined;

  @Prop(String) readonly baseCurrency: string | undefined;
  @Prop(Boolean) readonly history: boolean | undefined;
  @Prop(Boolean) readonly statement: boolean | undefined;
  @Prop(Boolean) readonly tradingOpers: boolean | undefined;
  @Prop(Boolean) readonly financeOpersOther: boolean | undefined;
  @Prop(Boolean) readonly financeOpersBase: boolean | undefined;
  @Prop(Boolean) readonly blockchainOpers: boolean | undefined;

  processing = false
  visibleActions = false;
  actionsMode = ''

  get baseBalance(): number {
    if (this.item) {
      return this.item.balance * this.item.realRate
    } else {
      return 0
    }
  }

  get currency(): ICurrency {
    return wallet.currIndex[this.baseCurrency || settings.company.companyBaseCurrency] || $ph.clone(CURRENCY_DEFAULT)
  }

  get lineWidth() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 1
    } else if (this.$vuetify.breakpoint.lgAndDown) {
      return 2
    } else {
      return 3
    }
  }

  get gradient() {
    if (this.item && this.item.history && this.item.history.priceChange < 0) {
      return settings.company.companyUISettings.graphs.error.split(',')
    } else if (this.item && this.item.history && this.item.history.priceChange >= 0) {
      return settings.company.companyUISettings.graphs.success.split(',')
    } else {
      return settings.company.companyUISettings.graphs.normal.split(',')
    }
  }

  get isBuyAllowed() {
    return this.item && (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatExchange : wallet.identCurrent.hasCryptoExchange)
  }

  get isSellAllowed() {
    return this.item && (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatExchange : wallet.identCurrent.hasCryptoExchange)
  }

  get isTopupAllowed() {
    return this.item && (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatTopup : wallet.identCurrent.hasCryptoGet) &&
      wallet.identCurrent.customerTopupAllowed > 0
  }

  get isWithdrawAllowed() {
    return this.item && (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatWithdraw : wallet.identCurrent.hasCryptoSend) &&
      wallet.identCurrent.customerWithdrawAllowed > 0
  }

  get showFiatOpers() {
    return this.item && ((this.financeOpersBase && this.item.currency.currencyCode === this.baseCurrency) || (this.financeOpersOther && this.item.currency.currencyCode !== this.baseCurrency))
  }

  get buttons() {
    const items: Array<any> = [];

    if (this.item) {
      if (this.tradingOpers && this.item.currencyCode !== this.baseCurrency) {
        items.push({ caption: 'dashboard.operations.buy', disabled: !this.isBuyAllowed, operMode: 'BUY' })
        items.push({ caption: 'dashboard.operations.sell', disabled: !this.isSellAllowed, operMode: 'SLL' })
      }

      if (this.item.currency.currencyType === CurrencyType.Crypto) {
        if (this.blockchainOpers) {
          items.push({ caption: 'dashboard.operations.send', disabled: !this.isWithdrawAllowed, operMode: 'SND' })
          items.push({ caption: 'dashboard.operations.get', disabled: !this.isTopupAllowed, operMode: 'GET' })
        }
      }

      if (this.item.currency.currencyType === CurrencyType.Fiat) {
        if (this.showFiatOpers) {
          items.push({ caption: 'dashboard.operations.topup', disabled: !this.isTopupAllowed, operMode: 'TOP' })
          items.push({ caption: 'dashboard.operations.send', disabled: !this.isWithdrawAllowed, operMode: 'WTH' })
        }
      }
    }

    return items;
  }

  get buttonSize() {
    return this.buttons.length === 0 ? 12 : Math.ceil(12 / this.buttons.length)
  }

  async createAccount(): Promise<IAccount | null> {
    if (this.item) {
      let account = this.item.account
      if (!account) {
        try {
          account = await wallet.appendAccount(this.item)
          await wallet.setDashboardItem({ item: this.item, data: { account } })
        } catch (err) {
          session.pushError(err)
          return null
        }
      }
      return account
    }
    return null
  }

  async open(mode: OperTypeModes) {
    this.visibleActions = false;
    if (this.item) {
      const account = await this.createAccount();
      if (account) {
        const oper = OPER_DEFAULT();
        oper.operMode = mode;
        oper.productCode = this.item.currency.productCode || 'CRYP'

        if (mode === OperTypeModes.Buy) {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = wallet.baseAccount.accountNumber
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currencyBase
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Sell) {
          oper.operInAccount = wallet.baseAccount.accountNumber
          oper.operOutAccount = account ? account.accountNumber : ''
          oper.operInCurrency = this.item.currencyBase
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Topup) {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = null
          oper.operInCurrency = this.item.currencyBase
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Get) {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = null
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Send || mode === OperTypeModes.Withdraw) {
          oper.operInAccount = null
          oper.operOutAccount = account ? account.accountNumber : ''
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = account ? account.accountNumber : ''
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        }

        wallet.setCurrentOperation(oper)
      }
    }
  }

  async changeFavorite(value: boolean) {
    this.visibleActions = false;
    this.processing = true
    try {
      if (this.item) {
        await wallet.storeDashboardItem({ item: this.item, data: { dashboardItemFavorite: value } })
      }
    } catch (err) {
      session.pushError(err)
    }
    this.processing = false
  }

  showActions(mode: string) {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.visibleActions = true
      this.actionsMode = mode
    }
  }

  openStatement(acc: IAccount | null) {
    if (acc) {
      this.$router.push({ path: '/statement', query: { account: acc.accountNumber } })
    }
  }
}

