
import { IPayMethod, IPayMethodSettings, OperTypeModes } from '../types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PaymentMethod extends Vue {
  @Prop() readonly value: IPayMethod | undefined;
  @Prop() readonly methods: Array<IPayMethod> | undefined;
  @Prop() readonly settings: Array<IPayMethodSettings> | undefined;
  @Prop() readonly mode: OperTypeModes | undefined;

  get selectCaption() {
    if (this.mode === OperTypeModes.Sell || this.mode === OperTypeModes.Withdraw) {
      return 'payment.method.select.withdraw'
    } else if (this.mode === OperTypeModes.Send) {
      return 'payment.method.select.wallet'
    } else {
      return 'payment.method.select'
    }
  }

  get addCaption() {
    if (this.mode === OperTypeModes.Sell || this.mode === OperTypeModes.Withdraw) {
      return 'payment.method.add.withdraw'
    } else if (this.mode === OperTypeModes.Send) {
      return 'payment.method.add.wallet'
    } else {
      return 'payment.method.add'
    }
  }

  select() {
    this.$emit('select')
  }

  add() {
    if (this.settings) {
      this.$emit('add', this.settings[0])
    }
  }
}

