
import $ph from '@/plugins/phoenix'
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DashboardHeader extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop(Boolean) readonly mini: boolean | undefined;
  @Prop(Boolean) readonly dense: boolean | undefined;
  @Prop(Boolean) readonly invert: boolean | undefined;

  get debug() {
    return this.$vuetify.icons
  }

  get userName() {
    return session.user.name
  }

  get userBaseCurr() {
    return session.user.userBaseCurrency
  }

  get totalAmount() {
    return $ph.format(wallet.total, { dec: wallet.baseCurrency.currencyPrecision })
  }

  get showCustomer() {
    return this.userName.toUpperCase() !== this.customerName.toUpperCase()
  }

  get customerName() {
    return wallet.customer ? wallet.customer.customerLegalName : ''
  }

  get customerNumber() {
    return wallet.customer ? wallet.customer.customerNumberPublic : ''
  }

  get isSelf() {
    return wallet.customer ? wallet.customer.customerType !== 'PRV' : true
  }
}

