import $ph from './phoenix'

export enum CardEventType {
  onCardError = 'onCardError',
  onCardLoad = 'onCardLoad',
  onCardStore = 'onCardStore',
  onCardComplete = 'onCardComplete',
  onCardToken = 'onCardToken',
  onCardReady = 'onCardReady',
}

export interface ICardEvent {
  type: CardEventType
  token: string | null
  input: string | null
  error: any
}

export type EventProcessor = (evt: ICardEvent) => void

export interface ICardWidgetConfig {
  token: string,
  widgetUrl: string,
  width: string,
  height: string,
  onEventProcessor: EventProcessor | null;
}

export interface ICardWidget {
  open(element: HTMLElement, config: ICardWidgetConfig): void;
  store(): Promise<boolean>;
}

class CardsWidget implements ICardWidget {
  private parent: HTMLElement | null = null
  private frame: HTMLIFrameElement | null = null
  private loader: HTMLElement | null = null
  private loaded = false
  private listenerAdded = false

  private config: ICardWidgetConfig = {
    token: '',
    widgetUrl: '',
    onEventProcessor: null,
    width: '100%',
    height: '300px',
  }

  onSuccessStore: any = null
  onFailStore: any = null

  open(el: HTMLElement, config: ICardWidgetConfig): void {
    if (el) {
      this.parent = el

      this.config.onEventProcessor = config.onEventProcessor || null;
      this.config.width = config.width || '100%';
      this.config.height = config.height || '300px';
      this.config.token = config.token || 'NO_TOKEN'
      this.config.widgetUrl = config.widgetUrl || ''

      el = document.createElement('div')
      el.style.width = '100%'
      el.style.height = '100%'
      el.innerHTML = 'Loading...'
      this.parent!.appendChild(el)
      this.loader = el

      if (!this.listenerAdded) {
        window.addEventListener('message', (event) => {
          if (event.origin !== this.config.widgetUrl) {
            $ph.warn('Parent income event not trusted! Received: ', event.origin, 'Expected: ', this.config.widgetUrl, 'Event: ', event)
            return
          } else {
            $ph.info('Event received from widget: ' + event.data.type)
          }

          this.messageProcessor(event.data)
        })
        this.listenerAdded = true
      }

      setTimeout(() => this.startLoad(), 100)
    }
  }

  sendEvent(evt: ICardEvent): void {
    if (this.frame && this.frame.contentWindow) {
      this.frame.contentWindow.postMessage(evt, this.config.widgetUrl);
      $ph.info('Event sent to widget: ' + evt.type)
    } else {
      $ph.warn('Widget frame not found!')
    }
  }

  async store(): Promise<boolean> {
    setTimeout(() => {
      this.sendEvent({
        type: CardEventType.onCardStore,
        token: this.config.token,
        input: null,
        error: null,
      })
    }, 300)

    return new Promise<boolean>((resolve, reject) => {
      this.onSuccessStore = resolve;
      this.onFailStore = reject;
    })
  }

  messageProcessor(evt: ICardEvent): void {
    if (evt) {
      if (evt.type === CardEventType.onCardLoad) {
        this.loaded = true
        this.completeLoad()
      } else if (evt.type === CardEventType.onCardError) {
        if (this.onFailStore === null) {
          this.failLoad(evt.error)
        } else {
          if (!evt.input) {
            this.onFailStore(evt.error)
          } else if (this.onSuccessStore !== null) {
            this.onSuccessStore(false)
          }
        }
      } else if (evt.type === CardEventType.onCardToken) {
        if (this.onSuccessStore !== null) {
          this.onSuccessStore(true)
        }
      }

      if (this.config.onEventProcessor) {
        this.config.onEventProcessor(evt)
      }
    }
  }

  startLoad(): void {
    this.loaded = false;
    let el: HTMLIFrameElement = document.createElement('iframe');
    el.onload = () => {
      this.checkLoad()
    };
    const url = this.config.widgetUrl + '/cards/widgets/' + this.config.token;
    el.setAttribute('src', url);
    el.style.width = this.config.width;
    el.style.height = this.config.height;
    el.style.borderWidth = '0';
    el.style.display = 'none'
    this.parent!.appendChild(el);
    this.frame = el;
  }

  checkLoad(): void {
    setTimeout(() => {
      if (!this.loaded) {
        this.failLoad('Timeout!');
        if (this.config.onEventProcessor) {
          this.config.onEventProcessor({ type: CardEventType.onCardError, error: { errorCode: 'CHL002', errorText: 'Loding widget error: timeout!' }, input: null, token: this.config.token })
        }
      } else {
        this.loader!.style.display = 'none'
        this.frame!.style.display = 'block'
      }
    }, 3000);
  }

  completeLoad(): void {
    this.loader!.style.display = 'none';
    this.frame!.style.display = 'block';
  }

  failLoad(error): void {
    this.loader!.innerHTML = 'Loading error!';
    /* eslint-disable no-console */
    console.error(error);
    /* eslint-enable no-console */
  }
}

const widget = new CardsWidget()

export default widget;
