
import { IAccountWidgetSettings } from '@/modules/system/types';
import settings from '@/plugins/settings';
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OPER_DEFAULT } from '../store';
import { CurrencyType, IAccount, ICurrency, OperTypeModes } from '../types';
import $ph from '@/plugins/phoenix';
import { WalletmelonAccess } from '@/modules/session/types';

@Component
export default class AccountCard extends Vue {
  @Prop() readonly item: any;
  @Prop() readonly itemSettings: any;
  @Prop() readonly favorite: any;

  processingFavorite = false
  showCustomize = false
  itemName = ''

  get rowStyle(): string {
    return 'height: 285px;'
  }

  get settings(): IAccountWidgetSettings {
    return this.itemSettings
  }

  get action() {
    return this.settings.action
  }

  get cardStyle() {
    return 'background: ' + this.settings.background + '; '
  }

  get baseBalance(): number {
    if (this.item) {
      return this.item.balance * this.item.realRate
    } else {
      return 0
    }
  }

  get lineWidth() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 1
    } else if (this.$vuetify.breakpoint.lgAndDown) {
      return 2
    } else {
      return 3
    }
  }

  get gradient() {
    if (this.item && this.item.history && this.item.history.priceChange < 0) {
      return settings.company.companyUISettings.graphs.error.split(',')
    } else if (this.item && this.item.history && this.item.history.priceChange >= 0) {
      return settings.company.companyUISettings.graphs.success.split(',')
    } else {
      return settings.company.companyUISettings.graphs.normal.split(',')
    }
  }

  get sysBaseCurrency(): string {
    return settings.company.companyBaseCurrency
  }

  get currency(): ICurrency {
    return wallet.baseCurrency
  }

  get isBuyAllowed() {
    return this.item &&
      $ph.access(WalletmelonAccess.WalletTransactionsExchange) &&
      (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatExchange : wallet.identCurrent.hasCryptoExchange)
  }

  get isSellAllowed() {
    return this.item &&
      $ph.access(WalletmelonAccess.WalletTransactionsExchange) &&
      (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatExchange : wallet.identCurrent.hasCryptoExchange)
  }

  get isTopupAllowed() {
    return this.item &&
      $ph.access(WalletmelonAccess.WalletTransactionsReceive) &&
      (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatTopup : wallet.identCurrent.hasCryptoGet) &&
      wallet.identCurrent.customerTopupAllowed > 0
  }

  get isWithdrawAllowed() {
    return this.item &&
      $ph.access(WalletmelonAccess.WalletTransactionsSend) &&
      (this.item.currency.currencyType === CurrencyType.Fiat ? wallet.identCurrent.hasFiatWithdraw : wallet.identCurrent.hasCryptoSend) &&
      wallet.identCurrent.customerWithdrawAllowed > 0
  }

  get buttons() {
    const items: Array<any> = [];

    if (this.settings.operations) {
      if (this.item.currency.currencyType === CurrencyType.Crypto && this.settings.operations.some(i => i === 'GET')) {
        items.push({ caption: 'dashboard.operations.get', disabled: !this.isTopupAllowed, operMode: 'GET' })
      }

      if (this.item.currency.currencyType === CurrencyType.Fiat && this.settings.operations.some(i => i === 'TOP')) {
        items.push({ caption: 'dashboard.operations.topup', disabled: !this.isTopupAllowed, operMode: 'TOP' })
      }

      if (this.settings.operations.some(i => i === 'BUY')) {
        items.push({ caption: 'dashboard.operations.buy', disabled: !this.isBuyAllowed, operMode: 'BUY' })
      }

      if (this.settings.operations.some(i => i === 'SLL')) {
        items.push({ caption: 'dashboard.operations.sell', disabled: !this.isSellAllowed, operMode: 'SLL' })
      }

      if (this.item.currency.currencyType === CurrencyType.Crypto && this.settings.operations.some(i => i === 'SND')) {
        items.push({ caption: 'dashboard.operations.send', disabled: !this.isWithdrawAllowed, operMode: 'SND' })
      }

      if (this.item.currency.currencyType === CurrencyType.Fiat && this.settings.operations.some(i => i === 'WTH')) {
        items.push({ caption: 'dashboard.operations.send', disabled: !this.isWithdrawAllowed, operMode: 'WTH' })
      }
    }

    return items;
  }

  get buttonSize() {
    return this.buttons.length === 0 ? 12 : Math.ceil(12 / this.buttons.length)
  }

  get priceColor() {
    if (this.item && this.item.history && this.item.history.priceChange && this.item.history.priceChange !== 0) {
      return this.item.history.priceChange < 0 ? 'error' : 'success'
    } else {
      return 'normal'
    }
  }

  get hasBalance(): boolean {
    return this.item.balance && this.item.balance !== 0 && this.item.currencyCode !== this.sysBaseCurrency
  }

  get hasHold(): boolean {
    return this.item.account && this.item.account.accountBalHold && this.item.account.accountBalHold > 0
  }

  customizeDashboardItem() {
    if (this.item.account) {
      this.itemName = (!this.item.dashboardItemName || this.item.dashboardItemName === '' ? this.item.account.accountName : this.item.dashboardItemName) || ''
    } else {
      this.itemName = !this.item.dashboardItemName || this.item.dashboardItemName === '' ? '' : this.item.dashboardItemName
    }
    this.showCustomize = true
    this.$nextTick(() => {
      (this.$refs.customName as any).$refs.input.focus()
    })
  }

  hideCustomization() {
    setTimeout(() => {
      this.showCustomize = false
    }, 300)
  }

  async setCustomization(apply: boolean) {
    this.showCustomize = false
    if (!apply) {
      this.itemName = ''
    }

    this.processingFavorite = true
    try {
      if (this.item) {
        await wallet.storeDashboardItem({ item: this.item, data: { dashboardItemName: this.itemName } })
      }
    } catch (err) {
      session.pushError(err)
    }
    this.processingFavorite = false
  }

  async changeFavorite(value: boolean) {
    this.processingFavorite = true
    try {
      if (this.item) {
        await wallet.storeDashboardItem({ item: this.item, data: { dashboardItemFavorite: value } })
      }
    } catch (err) {
      session.pushError(err)
    }
    this.processingFavorite = false
  }

  async createAccount(): Promise<IAccount | null> {
    if (this.item) {
      this.processingFavorite = true
      let account = this.item.account
      if (!account || !account.accountNumber) {
        try {
          account = await wallet.appendAccount(this.item)
          await wallet.setDashboardItem({ item: this.item, data: { account } })
          await wallet.refreshBaseData(session.user)
        } catch (err) {
          session.pushError(err)
          return null
        }
      }
      this.processingFavorite = false
      return account
    }
    return null
  }

  async doAction() {
    if (this.action === 'statement' && this.item.account) {
      this.$router.push({ path: '/statement', query: { account: this.item.account.accountNumber } })
    } else if (this.action === 'processing') {
      this.$router.push({ path: '/requests' })
    } else if (this.action === 'transactions') {
      this.$router.push({ path: '/transactions' })
    }
  }

  async open(mode: OperTypeModes) {
    if (this.item) {
      const account = await this.createAccount();
      if (account) {
        const oper = OPER_DEFAULT();
        oper.operMode = mode;
        oper.productCode = this.item.currency.productCode || 'CRYP'

        if (mode === OperTypeModes.Buy) {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = wallet.baseAccount.accountNumber
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currencyBase
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Sell) {
          oper.operInAccount = wallet.baseAccount.accountNumber
          oper.operOutAccount = account ? account.accountNumber : ''
          oper.operInCurrency = this.item.currencyBase
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Topup) {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = null
          oper.operInCurrency = this.item.currencyBase
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Get) {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = null
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else if (mode === OperTypeModes.Send || mode === OperTypeModes.Withdraw) {
          oper.operInAccount = null
          oper.operOutAccount = account ? account.accountNumber : ''
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        } else {
          oper.operInAccount = account ? account.accountNumber : ''
          oper.operOutAccount = account ? account.accountNumber : ''
          oper.operInCurrency = this.item.currency.currencyCode
          oper.operOutCurrency = this.item.currency.currencyCode
          oper.operMainCurrency = this.item.currency.currencyCode
        }

        wallet.setCurrentOperation(oper)
      }
    }
  }
}

