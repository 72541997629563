
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ITransaction, ROW_NUMBER_END_BAL, ROW_NUMBER_START_BAL, ROW_NUMBER_TURNS } from '../types';

@Component
export default class DashboardItem extends Vue {
  @Prop() readonly items: Array<ITransaction> | undefined;
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop(Boolean) readonly settle: boolean | undefined;

  get ROW_NUMBER_START_BAL(): number {
    return ROW_NUMBER_START_BAL;
  }

  get ROW_NUMBER_TURNS(): number {
    return ROW_NUMBER_TURNS;
  }

  get ROW_NUMBER_END_BAL(): number {
    return ROW_NUMBER_END_BAL;
  }
}

