
import $ph from '@/plugins/phoenix';
import $cardWidget, { CardEventType, ICardEvent } from '@/plugins/cards';
import { wallet } from '@/plugins/store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICardToken, INetworkSettings, IPayMethod, IPayMethodSettings, PaymentMethodType } from '../types';
import { AppError } from '@/plugins/phoenix/library';

@Component
export default class PayMethodNew extends Vue {
  @Prop() readonly value: IPayMethod | undefined;
  @Prop() readonly settings: IPayMethodSettings | undefined;
  @Prop() readonly store: boolean | undefined;
  @Prop() readonly currency: string | undefined;
  @Prop() readonly networks: Array<INetworkSettings> | undefined

  loading = false
  preparing = false
  token: ICardToken | null = null

  methodNumber = ''
  invalidNumber = false
  methodName = ''
  invalidName = false
  methodMemo = ''
  methodCode = ''
  methodNetwork = ''

  @Watch('store')
  onStore(store: any) {
    if (store) {
      this.storeMethod()
    }
  }

  get formUI() {
    return this.value ? this.value.type.payMethodFormUI : 'static'
  }

  get borderColor(): string {
    return this.$vuetify.theme.dark ? 'hsla(0,0%,100%,.7)' : 'rgba(0,0,0,.42)'
  }

  get isInvalid(): boolean {
    if (this.formUI !== 'card' || this.formUI.startsWith('wallet')) {
      this.invalidNumber = !this.methodNumber || this.methodNumber.trim() === ''
      this.invalidName = !this.methodName || this.methodName.trim() === ''
      if (this.invalidNumber || this.invalidName) {
        return true
      }
    }
    return false
  }

  check() {
    if (this.isInvalid) {
      throw new AppError('FRT001', 'Required fields are empty!')
    }
  }

  async prepareMethod() {
    this.preparing = true;
    try {
      this.check()
      this.$emit('error', null)
      let method: IPayMethod = $ph.clone(this.value)
      method.payMethodNumber = this.methodNumber
      method.payMethodName = this.methodName
      method.payMethodMemo = this.methodMemo
      await wallet.preparePayMethod(method)
    } catch (err) {
      this.$emit('error', err)
    }
    this.preparing = false;
  }

  async storeMethod() {
    $ph.info('Storing method: ' + this.formUI)
    this.loading = this.formUI !== 'card';
    try {
      this.check()

      let method: IPayMethod = $ph.clone(this.value)

      if (this.formUI === 'card') {
        $ph.info('Storing card...')
        if (await $cardWidget.store()) {
          method.payMethodType = PaymentMethodType.Card
          method.payMethodToken = this.token!.cardTokenNumber
        } else {
          throw new Error($ph.i18n('system.errors.CARD_NUMBER'))
        }
      } else {
        method.payMethodNumber = this.methodNumber
        method.payMethodName = this.methodName
        method.payMethodMemo = this.methodMemo
        method.payMethodNetwork = this.methodNetwork
        method.payMethodIssuerCode = this.methodCode
      }

      if (method.type.payMethodMultiCurrency) {
        method.payMethodCurrency = 'XXX'
      } else {
        method.payMethodCurrency = this.currency || 'XXX'
      }

      method = await wallet.putPayMethod(method)
      this.$emit('stored', method)
    } catch (err) {
      this.$emit('error', err)
    }
    this.loading = false;
  }

  async prepareCards() {
    this.loading = true
    try {
      this.token = await wallet.createCardToken()
      if (this.token) {
        this.$emit('error', new Error($ph.i18n('system.errors.CARD_EMPTY')))
        const el = document.getElementById('___wml_card_widget___')
        if (el) {
          $cardWidget.open(el, {
            token: this.token.cardTokenNumber,
            widgetUrl: this.token.widgetUrl,
            width: '100%',
            height: '378px',
            onEventProcessor: this.cardEvents,
          })
        }
      }
    } catch (err) {
      this.loading = false
      this.$emit('error', err)
    }
  }

  cardEvents(evt: ICardEvent) {
    if (evt.type === CardEventType.onCardError) {
      this.loading = false
      if (evt.error) {
        if (evt.input === 'card-number') {
          evt.error.message = $ph.i18n('system.errors.CARD_NUMBER')
        } else if (evt.input === 'card-expire') {
          evt.error.message = $ph.i18n('system.errors.CARD_EXPIRED')
        } else if (evt.input === 'card-cvc') {
          evt.error.message = $ph.i18n('system.errors.CARD_CVC')
        } else if (evt.input === 'card-owner') {
          evt.error.message = $ph.i18n('system.errors.CARD_NAME')
        }
      }
      this.$emit('error', evt.error)
    } else if (evt.type === CardEventType.onCardComplete) {
      this.storeMethod()
    } else if (evt.type === CardEventType.onCardReady) {
      this.$emit('error', null)
    } else if (evt.type === CardEventType.onCardLoad) {
      this.loading = false
      this.$emit('loaded')
    }
  }

  mounted() {
    if (this.value) {
      this.methodNumber = ''
      this.methodName = ''
      this.methodMemo = ''

      this.invalidNumber = false
      this.invalidName = false

      if (!this.networks || this.networks.length === 0) {
        this.methodNetwork = ''
      } else {
        this.methodNetwork = this.networks[0].networkId
      }

      if (this.formUI === 'card') {
        this.$nextTick(this.prepareCards)
      } else {
        this.loading = false
        this.$emit('error', new AppError('FRT001', 'Required fields are empty!'))
        this.$emit('loaded')
      }
    }
  }
}

