
import { session } from '@/plugins/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class EmailChangeDialog extends Vue {
  @Prop() readonly value: any;
  @Prop() readonly newMail: any;
  @Prop() readonly processing: any;

  codeNew = '';
  codeOld = '';

  @Watch('visible')
  onShow(value: any) {
    if (value) {
      this.codeNew = '';
      this.codeOld = '';
    }
  }

  get visible(): boolean {
    return this.value
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get oldMail() {
    return session.user.userLogin
  }

  action() {
    this.$emit('action', this.codeOld + '|||' + this.codeNew)
  }
}
