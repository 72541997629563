
import { IMultifactor, IOTP, User2FAModes } from '@/modules/session/types';
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IChange2FA, Process2fa } from '../types';

export enum Process2faSteps {
  start = 'start',
  masterKey = 'masterKey',
  bindKey = 'bindKey',
  check = 'check',
  restore = 'restore',
}

@Component
export default class SecondFactorDialog extends Vue {
  @Prop() readonly value: any
  @Prop() readonly mode: any
  @Prop() readonly process: any
  @Prop() readonly required: any

  processing = false
  step: Process2faSteps = Process2faSteps.start
  checkCode = ''
  restoreKey = ''
  multifactor: IMultifactor | null = null
  invalidCode = false

  @Watch('visible')
  onShow() {
    this.step = Process2faSteps.start;
    this.multifactor = null;
    this.checkCode = ''
    this.invalidCode = false
  }

  get visible(): boolean {
    return this.value
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get mode2fa(): User2FAModes {
    return this.mode || User2FAModes.None;
  }

  get process2fa(): Process2fa {
    return this.process || Process2fa.Check;
  }

  get title() {
    if (this.process2fa === Process2fa.Init) {
      if (this.step === Process2faSteps.start) {
        return 'security.2fa.google.title'
      } else if (this.step === Process2faSteps.masterKey) {
        return 'security.2fa.masterKey.title'
      } else if (this.step === Process2faSteps.bindKey) {
        return 'security.2fa.bind.title'
      } else if (this.step === Process2faSteps.check) {
        return 'security.2fa.check.title'
      }
    } else if (this.process2fa === Process2fa.Change) {
      return 'security.2fa.check.title'
    } else if (this.process2fa === Process2fa.Required && session.user.mode2Fa === User2FAModes.None) {
      return 'security.2fa.required.all.title'
    } else if (this.process2fa === Process2fa.Required && session.user.mode2Fa === User2FAModes.Logon) {
      return 'security.2fa.required.sgn.title'
    } else if (this.process2fa === Process2fa.Required && session.user.mode2Fa === User2FAModes.Sign) {
      return 'security.2fa.required.lgn.title'
    } else if (this.process2fa === Process2fa.Check) {
      if (this.step === Process2faSteps.start) {
        return 'security.2fa.check.title'
      } else if (this.step === Process2faSteps.restore) {
        return 'security.2fa.restore.title'
      }
    }
    return ''
  }

  get actionLabel() {
    if (this.process2fa === Process2fa.Init) {
      if (this.step === Process2faSteps.start) {
        return 'security.2fa.google.installed'
      } else if (this.step === Process2faSteps.masterKey) {
        return 'security.2fa.masterKey.copied'
      } else if (this.step === Process2faSteps.bindKey) {
        return 'security.2fa.bind.done'
      } else if (this.step === Process2faSteps.check) {
        return 'security.2fa.check.action'
      }
    }
    return ''
  }

  get masterKey() {
    if (this.multifactor && this.multifactor.multiFactorMasterKey.length > 12) {
      let key: string = this.multifactor.multiFactorMasterKey;
      return key.substring(0, 4) + '-' + key.substring(4, 8) + '-' + key.substring(8, 12) + '-' + key.substring(12, 16)
    } else {
      return ''
    }
  }

  get secretKey() {
    if (this.multifactor && this.multifactor.multiFactorSecret) {
      return this.multifactor.multiFactorSecret
    } else {
      return ''
    }
  }

  get barCode() {
    if (this.multifactor && this.multifactor.barCode) {
      return this.multifactor.barCode
    } else {
      return ''
    }
  }

  back() {
    if (this.process2fa === Process2fa.Init) {
      if (this.step === Process2faSteps.check) {
        this.step = Process2faSteps.bindKey
      } else if (this.step === Process2faSteps.bindKey) {
        this.step = Process2faSteps.masterKey
      } else if (this.step === Process2faSteps.masterKey) {
        this.step = Process2faSteps.start
      }
    } else if (this.process2fa === Process2fa.Check) {
      if (this.step === Process2faSteps.restore) {
        this.step = Process2faSteps.start
      }
    }
  }

  forget() {
    this.step = Process2faSteps.restore
  }

  logout() {
    this.$emit('logout')
  }

  async check() {
    let otp: IOTP = {
      code: this.checkCode,
      factorId: this.multifactor ? this.multifactor.multiFactorId : null,
      provider: 'GOOGLE',
    }
    await session.userOTP(otp)
    await session.confirmSession();
    this.multifactor = null
    this.$emit('refresh')
  }

  async next() {
    this.invalidCode = false
    this.processing = true;
    try {
      if (this.process2fa === Process2fa.Init) {
        if (this.step === Process2faSteps.start) {
          const rq: IChange2FA = {
            mode: this.mode2fa,
            multifactor: null,
            secureCode: '',
          };
          this.multifactor = await wallet.createFactor(rq);
          this.step = Process2faSteps.masterKey
        } else if (this.step === Process2faSteps.masterKey) {
          this.step = Process2faSteps.bindKey
        } else if (this.step === Process2faSteps.bindKey) {
          this.checkCode = ''
          this.step = Process2faSteps.check
        } else if (this.step === Process2faSteps.check) {
          await this.check()
        }
      } else if (this.process2fa === Process2fa.Change || this.process2fa === Process2fa.Required) {
        const rq: IChange2FA = {
          mode: this.mode2fa,
          multifactor: null,
          secureCode: this.checkCode,
        };
        await wallet.change2FA(rq);
        this.$emit('refresh')
      } else if (this.process2fa === Process2fa.Check) {
        if (this.step === Process2faSteps.start) {
          await this.check()
        } else if (this.step === Process2faSteps.restore) {
          await session.sendRecoveryOtp(this.restoreKey)
          this.$emit('logout')
        }
      }
    } catch (e) {
      const err: any = e
      if (err.errorCode === 'SYS007') {
        this.checkCode = ''
        this.invalidCode = true
      } else {
        session.pushError(err);
      }
    }
    this.processing = false;
  }
}
