
import $ph from '@/plugins/phoenix'
import { ProcessStates } from '@/modules/system/types';
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import settings from '@/plugins/settings';

@Component
export default class BannerIdent extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;

  processing = false;

  get bannerStyle() {
    let style = ''

    let background = settings.company.companyUISettings.banners.ident.background
    if (this.bannerMode === 'complete') {
      background = settings.company.companyUISettings.banners.ident.done
    } else if (this.bannerMode === 'failed') {
      background = settings.company.companyUISettings.banners.ident.failed
    }
    style += 'background: ' + background + '; '

    return style
  }

  get showVerifyButton(): boolean {
    return this.bannerMode === 'start' || this.bannerMode === 'correct' || this.bannerMode === 'continue'
  }

  get showCompleteButton(): boolean {
    return this.bannerMode === 'complete'
  }

  get showSupportButton(): boolean {
    return this.bannerMode === 'failed'
  }

  get bannerMode(): string {
    if (wallet.identNext.personLevelState === ProcessStates.None) {
      return 'start'
    } else if (wallet.identNext.personLevelState === ProcessStates.Suspended) {
      return 'correct'
    } else if (wallet.identNext.personLevelState === ProcessStates.Failed) {
      return 'failed'
    } else if (wallet.identNext.personLevelState === ProcessStates.WaitForAccept || wallet.identNext.personLevelState === ProcessStates.Done) {
      return 'complete'
    } else {
      return 'continue'
    }
  }

  verify() {
    wallet.setIdentVisible(true)
  }

  async complete() {
    this.processing = true;
    try {
      await wallet.completeIdent(wallet.identNext)
      if (wallet.identNext.identLevelComplete) {
        session.setUserIdentComplete(true)
      }
      window.location.reload()
    } catch (err) {
      this.processing = false;
      session.pushError(err);
    }
  }
}

