
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import $ph from '@/plugins/phoenix'
import { session, wallet } from '@/plugins/store';
import snsWebSdk from '@sumsub/websdk';
import { DocumentType, ICustomer, IIdentLevel, RedirectModes } from '../types';
import { IUser } from '@/modules/session/types';
import { ProcessStates } from '@/modules/system/types';

@Component
export default class IdentDialog extends Vue {
  preparing = false;
  lvl: IIdentLevel | null = null;

  @Watch('visible')
  onShow(value: any) {
    if (value) {
      this.prepareVerif()
    }
  }

  get visible(): boolean {
    return wallet.isIdentVisible
  }

  set visible(value: boolean) {
    wallet.setIdentVisible(value)
    wallet.refreshBaseData(session.user)
  }

  get frameHeigth() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return window.innerHeight - 220;
    } else {
      return window.innerHeight - (window.innerHeight * 0.3);
    }
  }

  get mode(): string {
    if (!session.error) {
      if (this.preparing) {
        return 'preparing'
      } else if (this.level) {
        if (this.level.identLevelRedirectMode === RedirectModes.Redirect) {
          return 'redirect'
        } else if (this.level.identLevelRedirectMode === RedirectModes.Frame) {
          return 'frame'
        } else if (this.level.identLevelRedirectMode === RedirectModes.Widget) {
          if (this.level.personLevelProvider === 'SUS') {
            return 'sumsub'
          }
        }
      }
    }
    return 'error'
  }

  get level(): IIdentLevel {
    return this.lvl || wallet.identNext
  }

  get user(): IUser {
    return session.user
  }

  get customer(): ICustomer | null {
    return wallet.customer
  }

  async prepareVerif() {
    this.preparing = true;
    session.pushError(null)
    if (this.customer) {
      try {
        const verif = await wallet.startIdent({
          level: this.level.identLevelId,
          docType: DocumentType.Passport,
          docCountry: this.customer.customerCountry,
          docFrontAttach: null,
          docBackAttach: null,
          utilityAttach: null,
          moneyAttach: null,
          customerCountry: this.customer.customerCountry,
        })

        this.lvl = $ph.clone(wallet.identNext)

        this.level.personLevelUrl = verif.verificationURL
        this.level.personLevelId = verif.verificationObject
        this.level.personLevelToken = verif.verificationData
        this.level.personLevelProvider = verif.verificationProvider

        if (this.level.personLevelState === ProcessStates.None) {
          this.level.personLevelState = ProcessStates.WaitForProcess
        }

        this.$nextTick(this.initWidget)
      } catch (err) {
        $ph.error(err)
      }
      this.preparing = false;
    }
    this.preparing = false;
  }

  initWidget() {
    if (this.mode === 'sumsub') {
      this.initSumSub()
    }
  }

  async initSumSub() {
    /* eslint-disable comma-dangle */
    await $ph.wait(300);
    let snsWebSdkInstance = snsWebSdk.init(this.level.personLevelToken || '', async () => this.level.personLevelToken || '')
      .withConf({
        lang: this.user.userLanguage,
        email: this.user.userLogin,
        phone: this.user.userPhone,
      })
      .withBaseUrl(this.level.personLevelUrl || '')
      .on('idCheck.onError', (error) => {
        session.pushError(error);
      })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container')
    /* eslint-enable comma-dangle */
  }
}
