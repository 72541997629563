
import $ph from '@/plugins/phoenix';
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ICustomerQuestionnaire } from '../types';

@Component
export default class QuestionnaireDialog extends Vue {
  @Prop() readonly value: any

  processing = false
  canSendQuest = true
  quest: ICustomerQuestionnaire | null = null

  @Watch('visible')
  onShow() {
    if (this.visible) {
      this.quest = $ph.clone(wallet.questionnaire)
    } else if (this.quest) {
      wallet.setQuestionnaire(this.quest)
    }
  }

  get visible(): boolean {
    return this.value
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get title() {
    return $ph.i18n(wallet.questionnaire ? wallet.questionnaire.questionnaireName : '')
  }

  get windowHeight() {
    const body = document.body
    const html = document.documentElement
    return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
  }

  get dialogHeight() {
    return (this.windowHeight - 150) + 'px';
  }

  get bodyHeight() {
    return (this.windowHeight - 300) + 'px';
  }

  async send() {
    if (this.quest) {
      this.processing = true;
      try {
        await session.storeQuestionnaire(this.quest)
        this.$emit('refresh')
      } catch (e) {
        session.pushError(e);
      }
      this.processing = false;
    }
  }
}
