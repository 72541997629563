
import { system } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TransactionDescr extends Vue {
  @Prop() readonly item: any;
  @Prop() readonly compact: any;

  get operTypes(): Record<string, string> {
    return system.operStyles
  }

  get isCompact(): boolean {
    return this.compact === '' || this.compact === 'true' || this.compact === true
  }
}

