
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICustomerQuestionnaire, ICustomerQuestionnaireQuestion, ICustomerQuestionnaireSection } from '../types';

@Component
export default class CustomerQuestionnaire extends Vue {
  @Prop() readonly questionnaire: any;
  @Prop() readonly bodyHeight: any;

  sections: Record<string, ICustomerQuestionnaireSection> = {}
  questions: Record<string, ICustomerQuestionnaireQuestion> = {}

  @Watch('questionnaire', { immediate: true, deep: false })
  onInputChanged(questionnaire: any) {
    this.indexActions(questionnaire)
  }

  @Watch('currentQuest', { immediate: true, deep: true })
  onDataChanged(questionnaire: any) {
    this.processActions(questionnaire)
  }

  get currentQuest(): ICustomerQuestionnaire {
    this.processActions(this.questionnaire)
    return this.questionnaire;
  }

  indexActions(questionnaire: ICustomerQuestionnaire) {
    const sections: Record<string, ICustomerQuestionnaireSection> = {}
    const questions: Record<string, ICustomerQuestionnaireQuestion> = {}

    if (questionnaire && questionnaire.sections) {
      questionnaire.sections.forEach(section => {
        sections[section.questSectionId] = section
        section.enabled = true
        section.visible = true
        if (section.questions) {
          section.questions.forEach(quest => {
            quest.enabled = true
            quest.visible = true
            questions[quest.questionId] = quest
          })
        }
      })
    }

    this.sections = sections
    this.questions = questions
  }

  checkSend(questionnaire: ICustomerQuestionnaire) {
    let canSend = true;
    if (questionnaire && questionnaire.sections) {
      questionnaire.sections.forEach(section => {
        if (section.questions) {
          section.questions.forEach(quest => {
            if (quest.questionRequired && quest.visible && quest.enabled && section.visible && section.enabled) {
              const empty: boolean = !quest.customerQuestionValue || ('' + quest.customerQuestionValue).trim() === ''
              canSend = canSend && !empty
            }
          })
        }
      })
    }

    this.$emit('onCanSend', canSend)
  }

  processActions(questionnaire: ICustomerQuestionnaire) {
    this.checkSend(questionnaire)
    if (questionnaire && questionnaire.actions) {
      questionnaire.actions.forEach(action => {
        // INIT FIELD/SECTION
        if (action.questSectionId && this.sections[action.questSectionId]) {
          this.sections[action.questSectionId].visible = action.questActionMethod !== 'show';
          this.sections[action.questSectionId].enabled = action.questActionMethod !== 'enable';
        }

        if (action.questionId && this.questions[action.questionId]) {
          this.questions[action.questionId].visible = action.questActionMethod !== 'show';
          this.questions[action.questionId].enabled = action.questActionMethod !== 'enable';
        }

        // CHECK CONDITIONS
        let doAction = action.questActionMode === 'and'

        if (action.conditions) {
          action.conditions.forEach(cond => {
            let valA: any = cond.questConditionSideA;
            let valB: any = cond.questConditionSideB;

            if (valA.startsWith('@')) {
              valA = this.questions[valA.substring(1)].customerQuestionValue
            }

            if (valB.startsWith('@')) {
              valB = this.questions[valB.substring(1)].customerQuestionValue
            }

            if (cond.questConditionOperator === '=') {
              if (action.questActionMode === 'and') {
                doAction = doAction && (valA === valB)
              } else {
                doAction = doAction || (valA === valB)
              }
            } else if (cond.questConditionOperator === '=') {
              if (action.questActionMode === 'and') {
                doAction = doAction && (valA !== valB)
              } else {
                doAction = doAction || (valA !== valB)
              }
            } else if (cond.questConditionOperator === '>') {
              if (action.questActionMode === 'and') {
                doAction = doAction && (valA > valB)
              } else {
                doAction = doAction || (valA > valB)
              }
            } else if (cond.questConditionOperator === '>=') {
              if (action.questActionMode === 'and') {
                doAction = doAction && (valA >= valB)
              } else {
                doAction = doAction || (valA >= valB)
              }
            } else if (cond.questConditionOperator === '<') {
              if (action.questActionMode === 'and') {
                doAction = doAction && (valA < valB)
              } else {
                doAction = doAction || (valA < valB)
              }
            } else if (cond.questConditionOperator === '<=') {
              if (action.questActionMode === 'and') {
                doAction = doAction && (valA <= valB)
              } else {
                doAction = doAction || (valA <= valB)
              }
            }
          })
        }

        // DO ACTION
        if (doAction) {
          if (action.questSectionId && this.sections[action.questSectionId]) {
            if (action.questActionMethod === 'show') {
              this.sections[action.questSectionId].visible = true;
            } else if (action.questActionMethod === 'hide') {
              this.sections[action.questSectionId].visible = false;
            }
            if (action.questActionMethod === 'enable') {
              this.sections[action.questSectionId].enabled = true;
            } else if (action.questActionMethod === 'disable') {
              this.sections[action.questSectionId].enabled = false;
            }
          }

          if (action.questionId && this.questions[action.questionId]) {
            if (action.questActionMethod === 'show') {
              this.questions[action.questionId].visible = true;
            } else if (action.questActionMethod === 'hide') {
              this.questions[action.questionId].visible = false;
            }
            if (action.questActionMethod === 'enable') {
              this.questions[action.questionId].enabled = true;
            } else if (action.questActionMethod === 'disable') {
              this.questions[action.questionId].enabled = false;
            }
          }
        }
      })
    }
  }
}

