
import { system } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TransactionAmount extends Vue {
  @Prop() readonly item: any;

  get operTypes(): Record<string, string> {
    return system.operStyles
  }
}

