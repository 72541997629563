
import $cardWidget, { CardEventType, ICardEvent } from '@/plugins/cards';
import { EntityStates, ProcessingMethods, ProcessStates } from '@/modules/system/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IBrandTerminal, ICardToken, ICurrency, IProcessingRequest, IProcessingRequestBase, POSMode, ProcessingPaymentMethodType } from '../types';
import $ph from '@/plugins/phoenix';
import { session, system, wallet } from '@/plugins/store';
import { CURRENCY_DEFAULT } from '../store';
import { ILanguage } from '@/plugins/phoenix/types';
import settings from '@/plugins/settings';

const DEFAULT_REQUEST: IProcessingRequestBase = {
  requestTestMode: false,
  requestMethod: ProcessingMethods.Deposit,
  requestState: ProcessStates.Created,
  requestTerminal: '',
  requestTracking: '',
  requestAmount: 0,
  requestCurrency: '',
  requestDescription: '',
  requestReason: 'ch',
  requestCountry: '',
  requestPayMethodType: ProcessingPaymentMethodType.Card,
  requestPayMethodToken: null,
  requestCustomerFirstName: '',
  requestCustomerLastName: '',
  requestCustomerCountry: '',
  requestCustomerEmail: '',
  requestCustomerPhone: '',
  requestCustomerAdrCity: '',
  requestCustomerAdrCountry: '',
  requestCustomerAdrLine1: '',
  requestCustomerAdrLine2: '',
  requestCustomerAdrZip: '',
  requestCustomerAdrState: '',
  requestDisplayLanguage: session.currentLanguage.code,
  requestBrowserData: '',
}

@Component
export default class POSDialog extends Vue {
  @Prop() readonly value: any;
  @Prop() readonly mode: any;

  processing = false
  processingCard = false
  step = 'payment'
  error = ''
  request: IProcessingRequestBase = $ph.clone(DEFAULT_REQUEST)
  token: ICardToken | null = null
  checker: number = 0
  redirect = ''

  @Watch('visible')
  onShow(value: any) {
    if (value) {
      this.resetData()
    }
  }

  get actionCaption() {
    if (this.mode === POSMode.Payout) {
      return 'customers.route-types.payout'
    } else if (this.mode === POSMode.Link) {
      return 'customers.route-types.link'
    } else {
      return 'customers.route-types.deposit'
    }
  }

  get showCheckout(): boolean {
    return system.showCheckoutPOS
  }

  get showLink(): boolean {
    return system.showLinkPOS
  }

  get showPayout(): boolean {
    return system.showPayoutPOS
  }

  get visible(): boolean {
    return this.value
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get frameHeigth() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return window.innerHeight - 220;
    } else {
      return window.innerHeight - (window.innerHeight * 0.3);
    }
  }

  get cardNumber() {
    return this.token && this.token.cardNumber ? this.token.cardNumber : null
  }

  get countries() {
    return system.countries
  }

  get languages(): Array<ILanguage> {
    return system.languages
  }

  get terminals(): Array<IBrandTerminal> {
    const list: Array<IBrandTerminal> = []
    if (wallet.customer && wallet.customer.brands) {
      wallet.customer.brands.forEach(brand => {
        if (brand.brandState === EntityStates.Active && brand.terminals) {
          brand.terminals.forEach(t => {
            const trm: IBrandTerminal = $ph.clone(t)
            if (trm.terminalState === EntityStates.Active &&
              ((this.mode === POSMode.Payout && Boolean(trm.terminalPayouts)) || (this.mode !== POSMode.Payout && Boolean(trm.terminalCheckouts)))) {
              trm.brandNumber = brand.brandNumber
              trm.brandName = brand.brandName
              trm.showkey = false
              trm.editing = false
              trm.color = trm.processing && trm.processing.processingTypeIsLive ? 'success' : 'error'
              list.push(trm)
            }
          })
        }
      })
    }
    return list
  }

  get terminal(): IBrandTerminal | null {
    return this.terminals.find(tr => tr.terminalNumber === this.request.requestTerminal) || null
  }

  get currencies(): Array<ICurrency> {
    const list: Array<ICurrency> = []
    if (this.terminal) {
      this.terminal.currencies.forEach(c => {
        const curr = wallet.currIndex[c.currencyCode || 'XXX']
        if (curr) {
          list.push(curr)
        }
      })
    }
    return list
  }

  get currency(): ICurrency {
    return wallet.currIndex[this.request.requestCurrency || settings.company.companyBaseCurrency] || $ph.clone(CURRENCY_DEFAULT)
  }

  async openCard() {
    this.processingCard = true
    this.step = 'card'
    try {
      this.token = await wallet.createProcessingCardToken()
      if (this.token) {
        const el = document.getElementById('___wml_card_widget___')
        if (el) {
          $cardWidget.open(el, {
            token: this.token.cardTokenNumber,
            widgetUrl: this.token.widgetUrl,
            width: '100%',
            height: '378px',
            onEventProcessor: this.cardEvents,
          })
        }
      }
    } catch (err) {
      session.pushError(err)
    }
  }

  cardEvents(evt: ICardEvent) {
    if (evt.type === CardEventType.onCardError) {
      this.processingCard = false
      if (evt.error) {
        if (evt.input === 'card-number') {
          this.error = $ph.i18n('system.errors.CARD_NUMBER')
        } else if (evt.input === 'card-expire') {
          this.error = $ph.i18n('system.errors.CARD_EXPIRED')
        } else if (evt.input === 'card-cvc') {
          this.error = $ph.i18n('system.errors.CARD_CVC')
        } else if (evt.input === 'card-owner') {
          this.error = $ph.i18n('system.errors.CARD_NAME')
        }
      }
    } else if (evt.type === CardEventType.onCardComplete) {
      this.storeCard()
    } else if (evt.type === CardEventType.onCardReady) {
      this.error = ''
    } else if (evt.type === CardEventType.onCardLoad) {
      this.processingCard = false
    }
  }

  async storeCard() {
    try {
      if (this.token) {
        if (await $cardWidget.store()) {
          this.token = await wallet.getCardToken(this.token.cardTokenNumber)
          this.request.requestPayMethodToken = this.token.cardTokenNumber
          this.step = 'payment'
        } else {
          this.error = $ph.i18n('system.errors.CARD_NUMBER')
        }
      }
    } catch (err) {
      session.pushError(err)
    }
  }

  async createRequest() {
    this.step = this.mode === POSMode.Link ? 'link' : 'redirect'
    try {
      if (this.terminal) {
        this.request.requestTestMode = !this.terminal.processing.processingTypeIsLive
        this.request.requestMethod = this.mode === POSMode.Payout ? ProcessingMethods.Payout : ProcessingMethods.Deposit
        this.request.requestDisplayMode = this.mode === POSMode.Link ? 'r' : 'f'
        this.request.requestDisplayLanguage = session.currentLanguage.code
        this.request.requestBrowserData = JSON.stringify({
          acceptHeaders: 'text/html,application/xhtml+xml,application/xml',
          javaEnabled: true,
          language: navigator.language,
          colorDepth: 48,
          screenWidth: 600,
          screenHeight: 800,
          timeZoneOffsetMin: new Date().getTimezoneOffset(),
          userAgent: window.navigator.userAgent,
        })
      }

      this.request = await wallet.createProcessingRequest(this.request)

      if (this.isFailed) {
        this.showRequestError()
      }

      if (this.mode === POSMode.Link) {
        let getUrl = window.location;
        this.redirect = getUrl.protocol + '//' + getUrl.host + '/checkout.html?token=' + this.request.requestId
      } else {
        this.checker = setInterval(this.checkRequest, 1000)
      }
    } catch (err) {
      console.log('REQUEST ERROR!!!')
      this.prepareError(err)
    }
  }

  get isFinalState(): boolean {
    return this.isSuccess || this.isFailed
  }

  get isSuccess(): boolean {
    return this.request &&
      (this.request.requestState === ProcessStates.Done ||
        this.request.requestState === ProcessStates.Committed ||
        this.request.requestState === ProcessStates.PostCommitWarn)
  }

  get isFailed(): boolean {
    return this.request &&
      (this.request.requestState === ProcessStates.Failed ||
        this.request.requestState === ProcessStates.Canceled ||
        this.request.requestState === ProcessStates.CancelFail)
  }

  prepareError(err: any) {
    this.request.requestState = ProcessStates.Failed
    this.request.requestResultCode = err.errorCode || 'SYS001'
    this.request.requestResultMessage = err.message || err.errorText || ''
    this.showRequestError()
  }

  showRequestError() {
    let errCode = 'SYS001'
    let errMessage = 'Fatal error: request not created!'

    if (this.request) {
      errCode = this.request.requestResultCode || 'SYS001'
      errMessage = this.request.requestResultMessage || ''
    }

    let errKey = 'system.errors.' + errCode
    let errMsgLocal = $ph.i18n(errKey)

    if (errKey !== errMsgLocal) {
      this.error = '[' + this.request.requestResultCode + '] ' + errMsgLocal
    } else {
      this.error = '[' + this.request.requestResultCode + '] ' + errMessage
    }
  }

  resetData() {
    this.step = 'payment'
    this.error = ''
    this.token = null
    this.request = $ph.clone(DEFAULT_REQUEST)
    this.redirect = ''
    if (wallet.customer) {
      this.request.requestTracking = wallet.customer.customerNumber + '-' + Date.now() + '-' + Math.round(Math.random() * 100000000)
    }
  }

  async checkRequest() {
    if (this.visible) {
      try {
        const request = await wallet.getProcessingRequest(this.request.requestId || 'NONE')
        if (request) {
          this.request = request
          if (!this.isFinalState) {
            if (this.redirect === '' && this.request.requestMethod === ProcessingMethods.Deposit) {
              let getUrl = window.location;
              this.redirect = getUrl.protocol + '//' + getUrl.host + '/checkout.html?token=' + this.request.requestId
            }
          } else {
            clearInterval(this.checker);
            if (this.isFailed) {
              this.showRequestError()
            }
          }
        } else {
          clearInterval(this.checker);
          this.showRequestError()
        }
      } catch (err) {
        clearInterval(this.checker);
        this.prepareError(err)
      }
    } else {
      clearInterval(this.checker);
    }
  }
}
