
import { User2FAModes } from '@/modules/session/types';
import settings from '@/plugins/settings';
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Process2fa } from '../types';

@Component
export default class Banner2fa extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;

  sfaShow = false
  sfaMode = User2FAModes.LogonAndSign
  sfaProcess = Process2fa.Init

  get bannerStyle() {
    let style = ''
    style += 'background: ' + settings.company.companyUISettings.banners.secondFactor.background + '; '
    return style
  }

  refreshSettings() {
    this.$router.go(0)
  }

  action() {
    this.sfaShow = true;
  }

  async later() {
    await wallet.change2FA({ mode: User2FAModes.None, multifactor: null, secureCode: '' })
    session.setUserAskSetup2FA(false)
  }
}

