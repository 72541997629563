
import { wallet } from '@/plugins/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IOperation, IOperationFull, ITransaction } from '../types';

@Component
export default class DashboardItem extends Vue {
  @Prop() readonly transactions: Array<IOperationFull> | undefined;
  @Prop(Boolean) readonly loading: boolean | undefined;

  openPreview(operation: IOperationFull) {
    wallet.setCurrentPreview({ visible: true, operation })
  }
}

