
import { ProcessingMethods, ProcessStates } from '@/modules/system/types';
import $ph from '@/plugins/phoenix';
import { AppError } from '@/plugins/phoenix/library';
import { session, system, wallet } from '@/plugins/store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CURRENCY_DEFAULT } from '../store';
import { IBrandTerminal, ICurrency, IOperationFull, IProcessingRequest, IProcessingRequestBase, ITransaction, ProcessingPaymentMethodType } from '../types';

@Component
export default class TransactionDialog extends Vue {
  @Prop() readonly value: any;
  @Prop() readonly tranData: any;
  @Prop() readonly operData: any;
  @Prop() readonly requestData: any;

  refundAmount = 0
  processing = false

  @Watch('requestData')
  onShow(value: any) {
    if (value && this.request) {
      this.refundAmount = this.request.requestAmount - this.request.requestAmountRefunded
    }
  }

  get showRefund(): boolean {
    return system.showRefundPOS
  }

  get transaction(): ITransaction | null {
    return this.tranData || null;
  }

  get operation(): IOperationFull | null {
    return this.operData || null;
  }

  get request(): IProcessingRequest | null {
    return this.requestData || null;
  }

  get visible(): boolean {
    return this.value
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get operTypes(): Record<string, string> {
    return system.operStyles
  }

  get title() {
    if (this.operation) {
      return $ph.i18n('operations.modes.' + this.operation.operMode)
    } else if (this.request) {
      if (this.request.requestMethod === 'r') {
        return $ph.i18n('backoffice.processingTypes.refund')
      } else if (this.request.requestMethod === 'p') {
        return $ph.i18n('backoffice.processingTypes.payout')
      } else {
        return $ph.i18n('backoffice.processingTypes.checkout')
      }
    }
  }

  get operPublicRef() {
    if (this.operation) {
      const number = this.operation.operReference
      return number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }

    return ''
  }

  get sellCurrency(): ICurrency {
    if (this.operation) {
      return wallet.currIndex[this.operation.operOutCurrency] || CURRENCY_DEFAULT()
    }
    return CURRENCY_DEFAULT()
  }

  get buyCurrency(): ICurrency {
    if (this.operation) {
      return wallet.currIndex[this.operation.operInCurrency] || CURRENCY_DEFAULT()
    }
    return CURRENCY_DEFAULT()
  }

  get terminals(): Array<IBrandTerminal> {
    const list: Array<IBrandTerminal> = []
    if (wallet.customer && wallet.customer.brands) {
      wallet.customer.brands.forEach(brand => {
        if (brand.terminals) {
          brand.terminals.forEach(t => {
            const trm: IBrandTerminal = $ph.clone(t)
            trm.brandNumber = brand.brandNumber
            trm.brandName = brand.brandName
            trm.showkey = false
            trm.editing = false
            list.push(trm)
          })
        }
      })
    }
    return list
  }

  async createRefund() {
    try {
      if (this.request) {
        const terminal = this.terminals.find(t => t.terminalNumber === this.request!.requestTerminal)
        if (!terminal) {
          throw new AppError('SYS001', 'Terminal not found: ' + this.request.requestTerminal)
        }

        const refund: IProcessingRequestBase = {
          requestTestMode: this.request.requestTestMode,
          requestMethod: ProcessingMethods.Refund,
          requestState: ProcessStates.Created,
          requestTerminal: terminal.terminalNumber,
          requestTracking: 'RF-' + wallet.customer!.customerNumber + '-' + Date.now() + '-' + Math.round(Math.random() * 100000000),
          requestAmount: this.refundAmount,
          requestCurrency: this.request.requestCurrency,
          requestDescription: 'Refund from cabinet',
          requestReason: 'rn',
          requestCountry: this.request.requestCountry,
          requestPayMethodType: ProcessingPaymentMethodType.Card,
          requestCustomerFirstName: '',
          requestCustomerLastName: '',
          requestCustomerCountry: '',
          requestCustomerEmail: '',
          requestCustomerPhone: '',
          requestCustomerAdrCity: '',
          requestCustomerAdrCountry: '',
          requestCustomerAdrLine1: '',
          requestCustomerAdrLine2: '',
          requestCustomerAdrZip: '',
          requestCustomerAdrState: '',
          requestDisplayLanguage: session.currentLanguage.code,
          requestBrowserData: '',
          requestDisplayMode: 'f',
          requestOriginalId: this.request.requestId,
        }

        const response = await wallet.createProcessingRequest(refund)
        if (response.requestState === ProcessStates.Failed ||
          response.requestState === ProcessStates.Canceled ||
          response.requestState === ProcessStates.CancelFail) {
          throw new AppError(response.requestResultCode || 'SYS001', response.requestResultMessage || 'Refund failed')
        }
        this.visible = false
        this.$emit('refresh')
      }
    } catch (err) {
      session.pushError(err)
    }
  }
}
