
import settings from '@/plugins/settings';
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AccountTypeModes, CurrencyType, DiscretizationTypes } from '../types';

@Component
export default class DashboardPortfolio extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop(Boolean) readonly fiat: boolean | undefined;
  @Prop(Boolean) readonly crypto: boolean | undefined;

  refreshing = false
  discret = 1

  get balances() {
    return wallet.dashboardBalances || null
  }

  get gradient() {
    return settings.company.companyUISettings.graphs.error.split(',')
  }

  get lineWidth() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 1
    } else if (this.$vuetify.breakpoint.lgAndDown) {
      return 2
    } else {
      return 3
    }
  }

  get accounts() {
    /* eslint-disable comma-dangle */
    return wallet.accounts.filter(a =>
      a.accountBalAvailEq > 0 &&
      a.accountTypeMode === AccountTypeModes.CustomerCurrent &&
      (
        (this.fiat && a.currency.currencyType === CurrencyType.Fiat) ||
        (this.crypto && a.currency.currencyType === CurrencyType.Crypto)
      )
    ).sort((a, b) => b.accountBalAvailEq - a.accountBalAvailEq)
    /* eslint-enable comma-dangle */
  }

  get total() {
    return wallet.total
  }

  async changeDiscret(discret: DiscretizationTypes) {
    this.refreshing = true;
    try {
      wallet.setDashboardDiscret(discret)
      await wallet.loadBaseData(session.user)
    } catch (err) {
      session.pushError(err)
    }
    this.refreshing = false;
  }
}

