
import $ph from '@/plugins/phoenix'
import { IPayMethod, IPayMethodSettings, OperTypeModes } from '../types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { session, wallet } from '@/plugins/store';

@Component
export default class PaymentMethodsList extends Vue {
  @Prop() readonly settings: Array<IPayMethodSettings> | undefined;
  @Prop() readonly methods: Array<IPayMethod> | undefined;
  @Prop() readonly current: IPayMethod | undefined;
  @Prop() readonly mode: OperTypeModes | undefined;

  get payMethodsSettings(): Array<IPayMethodSettings> {
    const seen = {}
    return (this.settings || []).filter(grp => seen.hasOwnProperty(grp.payMethodType) ? false : (seen[grp.payMethodType] = true))
  }

  get payMethods(): Array<IPayMethod> {
    return this.methods || []
  }

  favorite(method: IPayMethod): boolean {
    if (this.mode === OperTypeModes.Buy && method.payMethodFavoriteBuy) {
      return true
    } else if (this.mode === OperTypeModes.Sell && method.payMethodFavoriteSell) {
      return true
    } else if (this.mode === OperTypeModes.Send && method.payMethodFavoriteSend) {
      return true
    } else if (this.mode === OperTypeModes.Get && method.payMethodFavoriteGet) {
      return true
    } else if (this.mode === OperTypeModes.Topup && method.payMethodFavoriteTopup) {
      return true
    } else if (this.mode === OperTypeModes.Withdraw && method.payMethodFavoriteWithdraw) {
      return true
    }

    return false
  }

  select(value: IPayMethod) {
    this.$emit('select', value)
  }

  add(value: IPayMethodSettings) {
    this.$emit('add', value)
  }

  async remove(value: IPayMethod) {
    try {
      await wallet.removePayMethod(value)
      if (this.current && this.current.payMethodType === value.payMethodType && this.current.payMethodNumber === value.payMethodNumber) {
        this.$emit('clear')
      }
    } catch (err) {
      session.pushError(err)
    }
  }

  async setFavorite(current: IPayMethod) {
    if (!this.favorite(current)) {
      try {
        const updated = $ph.clone(current)

        if (this.mode === OperTypeModes.Buy) {
          updated.payMethodFavoriteBuy = true
        } else if (this.mode === OperTypeModes.Sell) {
          updated.payMethodFavoriteSell = true
        } else if (this.mode === OperTypeModes.Send) {
          updated.payMethodFavoriteSend = true
        } else if (this.mode === OperTypeModes.Get) {
          updated.payMethodFavoriteGet = true
        } else if (this.mode === OperTypeModes.Topup) {
          updated.payMethodFavoriteTopup = true
        } else if (this.mode === OperTypeModes.Withdraw) {
          updated.payMethodFavoriteWithdraw = true
        }

        await wallet.changePayMethod({ current, updated })
      } catch (err) {
        session.pushError(err)
      }
    }
  }
}

